/* TaxesByAddress.css */
.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 20px;
  margin: auto;
  width: 50%; /* Adjust this value as needed */
}

.taxes-by-address {
  font-family: sans-serif;
}

.taxes-by-address table {
  margin: auto;
  border-collapse: collapse;
  margin-bottom: 50px;
}

.taxes-by-address th {
  background-color: #000000;
  padding: 10px;
  text-align: left;
  color: white;
  word-wrap: break-word;
  max-width: 100px;
}

.taxes-by-address td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.taxes-by-address .total-row {
  background-color: #d9d9d9;
}
.state-header {
  display: flex;
  align-items: center;
}
.state-header h2 {
  margin-left: 10px;
}

/* tab sheet */
.totalRow {
  border-bottom: 3px solid black;
}

.cps {
  background-color: #ffdab9; /* pale orange */
}

.dare {
  background-color: #90ee90; /* light green */
}

.adj {
  border: 1px solid black;
  background-color: lightGrey;
}

.total-tax {
  border: 2px solid black;
}

.ava-tax {
  border: 2px solid black;
}

.zero-value {
  color: lightgrey;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
.MuiDataGrid-main.css-204u17-MuiDataGrid-main > div {
  height: auto !important;
}

.handsontable td.not-totals {
  background-color: rgb(240, 240, 240);
}

.hide-row {
  display: none;
}
.state-totals td:nth-child(5) {
  background-color: #f0f0f0;
  font-weight: bold;
}

.handsontable td.htDimmed {
  color: initial;
}

.handsontable .currentRow {
  background-color: yellow !important;
}

.handsontable td.cps {
  border-left: 3px solid black;
}
.handsontable td.cps-total {
  border-right: 2px solid black;
}
.handsontable td.dare {
  border-left: 2px solid black;
}
.handsontable td.dare-total {
  border-right: 3px solid black;
}

div.handsontable th {
  white-space: normal !important;
  max-width: 100px;
  background: black;
  color: white;
  font-weight: bold;
}
.wtHolder {
  height: auto !important;
}
th.ht__highlight {
  background: dodgerblue !important;
}

td.state-totals.adj-totals {
  background: lightblue;
}

.table-wrapper {
  margin: auto;
  display: table;
}
.grandtotal-wrapper {
  margin: auto;
  margin-bottom: 50px;
  display: table;
}

tr:hover td {
  background: yellow !important;
}

div.taxes-by-address .MuiTabs-root {
  margin: auto;
  display: table;
}
