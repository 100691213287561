body {
  margin-top: 60px;
}

.navigation {
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-family: sans-serif;
  text-transform: uppercase;
}

.nav-link:hover {
  color: black;
  background-color: white;
}
.active {
  text-decoration: underline;
}
ul.navbar-links > li > a {
  padding: 5px 35px;
}
ul.navbar-links > li {
  display: inline-flex;
}
